/**
 * Short helper to return classnames based on if case.
 *
 * @param classes
 */
import { IAppDefinition } from "../models/app";
import { IConfiguration } from "../models/configuration";
import { DeviceContent, IDevice, ISyncData } from "../models/device";
import { IProduct, ProductSortingString } from "../models/product";
import { IUserUrlHash } from "../models/user";
import { IVideoDefinition } from "../models/video";
import { IWebAppDefinition } from "../models/web-app";

/**
 * CONSTANTS
 */

export const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const VIL_OWNER_ID = 71;

//used as interval timer for automatic refreshes; also basis for sync timing
export const DEVICE_REFRESH_TIMER = 5_000; // 5 seconds

/**
 * Little helper to work with components class names.
 *
 * @param classes
 */

export function getClassNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

/**
 * Stolen from Stackoverflow
 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 *
 * @param input
 * @param decimals
 * @returns {string}
 */
export const formatBytes = (input: number, decimals: number = 2): string => {
  if (0 === input) return "0 Bytes";

  const bits = 1024;
  const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const parsed = Math.floor(Math.log(input) / Math.log(bits));

  return parseFloat((input / Math.pow(bits, parsed)).toFixed(decimals)) + " " + units[parsed];
};

/**
 * Formats bytes to a number with
 *
 * Todo: Add more documentation.
 *
 * @param bytes
 * @param decimalNumbers
 */
export const formatBytesToNumber = (bytes: number, decimalNumbers: number = 2): number => {
  if (0 === bytes) return 0;

  const bits = 1024;
  const parsedBytes = Math.floor(Math.log(bytes) / Math.log(bits));

  return Number(parseFloat((bytes / Math.pow(bits, parsedBytes)).toFixed(decimalNumbers)));
};

/**
 * Todo: Add more documentation.
 *
 * @param backEndString
 */
export const translateVideoTypeString = (backEndString: string) => {
  switch (backEndString) {
    case "180":
    case "3D180_LR":
    case "3D180_TB":
      return "180°";
    case "360":
    case "3D360_LR":
    case "3D360_TB":
      return "360°";
    default:
      return "360°";
  }
};

/**
 * Todo: Add more documentation.
 * Todo: Move this code to another place (e.g. devices model).
 *
 * @param devices
 * @param type
 */
export const sortDevices = (devices: Array<IDevice>, type = "ASC") => {
  let copy = devices.slice();

  if (type == "ASC") {
    return copy.sort((a, b) => {
      return a.positionNumber < b.positionNumber ? -1 : a.positionNumber > b.positionNumber ? 1 : 0;
    });
  } else if (type == "DESC") {
    return copy.sort((a, b) => {
      if (a.positionNumber < b.positionNumber) {
        return 1;
      }
      if (a.positionNumber > b.positionNumber) {
        return -1;
      }
      return 0;
    });
  } else return copy;
};

/**
 * Todo: Add more documentation.
 * Todo: This peace of code could be moved to another place.
 *
 * @param devices
 * @param filter
 */
export const filterDevices = (devices: Array<IDevice>, filter: string) => {
  return devices.filter(element => {
    if (filter === "online") {
      return element.isOnline;
    } else if (filter === "offline") {
      return !element.isOnline;
    } else if (filter === "synced") {
      return element.isInSync;
    } else if (filter === "async") {
      return !element.isInSync;
    } else {
      return true; // No filter or unrecognized filter, include all devices
    }
  });
};

/**
 * Converts seconds into a time string hh:mm:ss
 *
 * @param secondsInput
 */
export const secondsToTimeString = (secondsInput: number) => {
  const hours = Math.floor(secondsInput / 3600);
  const minutes = Math.floor(secondsInput / 60);
  const seconds = Math.round(secondsInput % 60);

  return seconds === 60
    ? `${hours > 0 ? `${hours}:` : ""}${minutes + 1}:00`
    : `${hours > 0 ? `${hours}:` : ""}${minutes}:${seconds.toString().padStart(2, "0")}`;
};

/**
 * Gets the duration of a video by its given url
 *
 * @param url Url of the video
 */
export const getVideoDuration = async (url: string) => {
  return new Promise<number>((resolve, reject) => {
    const video = document.createElement("video");
    video.setAttribute("preload", "metadata");

    video.onloadedmetadata = () => {
      const duration = video.duration;
      video.pause();
      video.removeAttribute("src");
      video.load();
      resolve(duration);
    };

    video.src = url;
  });
};

/**
 * Parses the E-mail and Organisation id from the URL Hash that getting provided after inviting a user.
 *
 * @see https://portal.vil.digital/register/[token]
 * @see https://vcs.apps.vil.group/api/docs/swagger/#/v1/v1_organisations_users_activate_create
 * @param token urlHash token
 */
export const parseEMailAndOrganisationIdFromUrlHash = (token: string): IUserUrlHash | null => {
  if (!token) {
    return null;
  }
  const firstChunk = token.split(":")[0];
  if (!firstChunk) {
    return null;
  }
  const base64 = firstChunk.replace("-", "+").replace("_", "/");
  return JSON.parse(Buffer.from(base64, "base64").toString("binary")) as IUserUrlHash;
};

export const daysDiffrenceToToday = (date: any) => {
  const currentDate = new Date();
  const parsedDate = new Date(date);
  const timeDiff = Math.abs(parsedDate.getTime() - currentDate.getTime());
  const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
  return diffDays;
};
/**
 * Truncates a given string to a given length at an end of a word.
 * Also adds " ..." at the end.
 * @param targetString
 * @param length
 */
export const truncateString = (targetString: string, length: number) => {
  if (targetString && targetString.length) {
    const cut = targetString.indexOf(" ", length);
    if (cut === -1) return targetString;
    return targetString.substring(0, cut) + " ...";
  }
  return targetString;
};

export const softwareUpdateNeeded = (currentVersion: string, newestVersion: string): boolean => {
  if (!currentVersion || !newestVersion) {
    return false;
  }

  const [currentMajor, currentMinor, currentBugfix] = currentVersion.split(".").map(Number);
  const [newestMajor, newestMinor, newestBugfix] = newestVersion.split(".").map(Number);

  return (
    currentMajor < newestMajor ||
    (currentMajor === newestMajor && currentMinor < newestMinor) ||
    (currentMajor === newestMajor && currentMinor === newestMinor && currentBugfix < newestBugfix)
  );
};

export const deviceIsElgibleToUseQueue = (currentXRAdminVersion: string) => {
  if (!currentXRAdminVersion) {
    return false;
  }

  const [major, minor, bugfix] = currentXRAdminVersion.split(".").map(Number);
  //4.0.1 needed for queue
  const neededMajor = 4;
  const neededMinor = 0;
  const neededBugfix = 1;

  return (
    major >= neededMajor &&
    (major > neededMajor || minor >= neededMinor) &&
    (major > neededMajor || minor > neededMinor || bugfix >= neededBugfix)
  );
};

export const deviceIsElgibleToUseScreencast = (currentXRAdminVersion: string) => {
  if (!currentXRAdminVersion) {
    return false;
  }

  const [major, minor, bugfix] = currentXRAdminVersion.split(".").map(Number);
  //4.5.0 needed for screencast
  const neededMajor = 4;
  const neededMinor = 5;
  const neededBugfix = 0;

  return (
    major >= neededMajor &&
    (major > neededMajor || minor >= neededMinor) &&
    (major > neededMajor || minor > neededMinor || bugfix >= neededBugfix)
  );
};
export const deviceIsElgibleToUseManufacturerMode = (currentXRAdminVersion: string) => {
  if (!currentXRAdminVersion) {
    return false;
  }

  const [major, minor, bugfix] = currentXRAdminVersion.split(".").map(Number);
  //4.7.0 needed for screencast
  const neededMajor = 4;
  const neededMinor = 7;
  const neededBugfix = 0;

  return (
    major >= neededMajor &&
    (major > neededMajor || minor >= neededMinor) &&
    (major > neededMajor || minor > neededMinor || bugfix >= neededBugfix)
  );
};

export const checkIfDeviceIsInSync = (deviceContent: DeviceContent[], config: IConfiguration) => {
  const configContent = [
    ...config?.videos.map(element => element.video),
    ...config?.apps.map(element => element.app),
    ...config?.webApps.map(element => element.webApp)
  ];

  const configUUIDs = new Set(configContent.map(item => item.uuid));
  const additionalContent = deviceContent.filter(
    item => !configUUIDs.has(item.uuid) && item.name !== "XRAdmin" && item.name !== "Launcher"
  );

  if (additionalContent.length > 0) return false;

  const deviceUUIDs = new Set(deviceContent.map(item => item.uuid));
  const missingContent = configContent.filter(item => !deviceUUIDs.has(item.uuid));

  return additionalContent.length === 0 && missingContent.length === 0;
};

export const returnSyncData = (deviceContent: DeviceContent[], config: IConfiguration) => {
  const configContent: (IAppDefinition | IWebAppDefinition | IVideoDefinition)[] = [
    ...config?.videos.map(element => element.video),
    ...config?.apps.map(element => element.app),
    ...config?.webApps.map(element => element.webApp)
  ];

  const deviceUUIDs = new Set(deviceContent.map(item => item.uuid));
  const missingContent = configContent
    .filter(item => !deviceUUIDs.has(item.uuid))
    .sort((a, b) => {
      return a.title.localeCompare(b.title);
    });

  const returnObject: ISyncData = {
    isInSync: missingContent.length === 0,
    missingContent: missingContent
  };

  return returnObject;
};

export const checkEmailPattern = (email: string) => {
  return EMAIL_PATTERN.test(email);
};

export const returnActiveDevicesObject = (config: IConfiguration, products: IProduct[]) => {
  let numberOfActiveDevices = 0;
  let activeSerialNumbers: string[] = [];
  let activeProducts: IProduct[] = [];
  products.forEach(product => {
    product.devices.forEach(device => {
      if (device.deviceConfiguration && device.deviceConfiguration.id === config.id) {
        numberOfActiveDevices++;
        activeSerialNumbers.push(product.serialNumber!);
        activeProducts.push(product);
      }
    });
  });
  const returnObject = {
    numberOfActiveDevices: numberOfActiveDevices,
    serialNumbers: Array.from(new Set(activeSerialNumbers)),
    activeProducts: Array.from(new Set(activeProducts))
  };
  return returnObject;
};

export const returnFormattedAgeRecommendation = (recommendation: number[]) => {
  let returnString = "Keine Altersempfehlung";
  /**
   * [0] = lower bound
   * [1] = upper bound
   */

  if (recommendation[0] > 0 && recommendation[1] > 0) {
    returnString = recommendation[0] + " bis " + recommendation[1] + " Jahre";
  }

  return returnString;
};

export const sortProducts = (products: IProduct[], sortingString: ProductSortingString) => {
  switch (sortingString) {
    case ProductSortingString.ALPHABET_ASC:
      return products.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    case ProductSortingString.ALPHABET_DESC:
      return products.sort((a, b) => {
        return b.name.localeCompare(a.name);
      });

    case ProductSortingString.OWNER_ALPHABET_ASC:
      return products.sort((a, b) => {
        return a.owner.name.localeCompare(b.owner.name);
      });

    case ProductSortingString.OWNER_ALPHABET_DESC:
      return products.sort((a, b) => {
        return b.owner.name.localeCompare(a.owner.name);
      });
    default:
      return products.sort((a, b) => {
        if (a.positionNumber > b.positionNumber) {
          return 1;
        }
        if (a.positionNumber < b.positionNumber) {
          return -1;
        }
        return 0;
      });
  }
};
