export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export enum Size {
  NAVBAR_HEIGHT = "60px"
}

export enum CloudCommand {
  REBOOT = "CMD.REBOOT",
  SHUTDOWN = "CMD.SHUTDOWN",
  SYNC = "CMD.SYNC",
  UPDATE = "CMD.UPDATE",
  MUTE = "CMD.MUTE",
  TOGGLE_PAUSE = "CMD.TOGGLE.PAUSE",
  START_APP = "CMD.START.APP",
  START_VIDEO = "CMD.START.VIDEO",
  START_WEBAPP = "CMD.START.WEBAPP",
  STOP_RUNNING = "CMD.STOP.RUNNING",
  TOGGLE_GUIDED_MODE = "CMD.SET.GUIDED.MODE",
  START_MANUFACTURER_MODE = "CMD.SET.MANUFACTURER.MODE"
}

export enum DeviceInfo {
  BASE_STORAGE_USED = 14.78,
  OLD_VALUE_STORAGE_PICO = 49.22,
  MAX_STORAGE_PICO_G24K = 64,
  MAX_STORAGE_PICO_NEO3PRO = 256,
  MAX_STORAGE_PICO_G3 = 128,
  MAX_STORAGE_PICO_4 = 256
}

export enum DashboardPath {
  DASHBOARD = "Dashboard",
  CONFIGURATIONS = "Dashboard / Konfigurationen",
  CONFIGURATION = "Dashboard / Konfigurationen / ",
  DEVICES = "Dashboard / Geräte",
  DEVICE = "Dashboard / Gerät / "
}

export enum WebsiteImg {
  DEFAULT_PROFILE = "",
  WELCOME = "",
  EMPTY = "",
  ERROR = ""
}

export interface BasicObject {
  id: number;
  name: string;
}

export enum WebsitePath {
  INDEX = "/",
  PRODUCTS = "#",
  ABOUT = "#",
  CONTACT = "#",
  IMPRINT = "/impressum",
  DATA_PROTECTION = "/datenschutzerklaerung",
  CONDITIONS = "/agbs",
  CONTENT = "/library",
  SUPPORT = "/support",
  LOGIN = "/login",
  DASHBOARD = "/dashboard",
  DEVICES = "/dashboard/devices",
  DEVICE = "/dashboard/devices/",
  CONFIGURATIONS = "/dashboard/configurations",
  CONFIGURATION = "/dashboard/configurations/",
  PROFILE = "/dashboard/profile",
  SETTINGS = "/dashboard/settings",
  OIDCANTARES = "/oidc-antares"
}

// Regex can't be stored in an enum
export const Regex = {
  // Longest tld is 24 characters long
  EMAIL_VALIDATION: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/,
  PASSWORD_VALIDATION: /(?=.*\d)(?=.*[A-Za-z]).{8,}/,
  FILENAME: /^[A-Za-z0-9\-_.]+$/iu
};
